<template>
  <v-row>
    <v-col cols="12" lg="8" xl="7">
      <v-card class="pa-4" flat>
        <v-card-title class="d-block px-0">
          <back-button-component/>
        </v-card-title>
        <v-card rounded-lg>
          <v-card-text>
            <v-form @submit.prevent="submit" style="width: 100%" class="d-flex align-center justify-space-between">
              <v-select :items="items"
                        style="max-width: 60%"
                        label="Your preffered date format"
                        placeholder="Please, choose your preffered date format"
                        @change="enable"
                        v-model="form.date_format"></v-select>
              <v-btn type="submit" color="primary"
                     rounded height="49" :disabled="disabled">Save settings</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DateFormat",
  data () {
    return {
      disabled: true,
      form: {
        date_format: 'Y.m.d'
      },
      items: [
        {
          text: 'American date format (default)',
          value: 'Y.m.d'
        },
        {
          text: 'European date format',
          value: 'd.m.Y'
        }
      ]
    }
  },
  mounted() {
    this.$store.getters.GET_USER.settings ?
        this.form.date_format = this.$store.getters.GET_USER.settings.date_format :
        null
  },
  methods: {
    enable () {
      this.disabled = false
    },
    submit () {
      !this.$store.getters.GET_USER.settings ?
      this.$http.post(`${process.env.VUE_APP_API_URL}/settings`, this.form) :
          this.$http.patch(`${process.env.VUE_APP_API_URL}/settings/${this.$store.getters.GET_USER.settings.id}`, this.form)
    }
  }
}
</script>

<style scoped>

</style>